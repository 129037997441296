import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
// import BackgroundImage from 'gatsby-background-image'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import certifications from '../../data/certifications';

SwiperCore.use([Autoplay, Pagination, Navigation]);

const Certifications = ({locale}) => {
  const localeCertifications = certifications[locale]

  const data = useStaticQuery(
    graphql`
      query {
        av: file(relativePath: { eq: "av.png" }) {
          childImageSharp {
            fluid(maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        milliondollar: file(relativePath: { eq: "milliondollar.png" }) {
          childImageSharp {
            fluid(maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )


  return (
      <div className="certifications bg-white  border-neutral-300 border text-center px-4 mx-auto h-full grid-cols-1 grid items-start ">   
      <h2 className="mx-4 text-blue text-center text-5xl mb-8 pt-8 pb-2 border-b">{localeCertifications.title}</h2>
      <Swiper
        className="p-12 w-full"
        spaceBetween={50}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        loop={true}
      >

        {localeCertifications.certifications.map((item, index) => {
            return (
              <SwiperSlide key={index} tag="figure" className="p-8 pb-12 text-lg space-y-3">
                <p>{item.text}</p>
                <figcaption className="uppercase tracking-wide text-sm font-normal">- {item.name}</figcaption>
              </SwiperSlide>
            )
        })}

      </Swiper>
      <div className="grid gap-4 grid-cols-2 self-end justify-items-center">
        <Img
          fluid={data.av.childImageSharp.fluid}
          alt="AV Preeminent Lawyer Rating Logo"
          className="m-4 w-32"
        />

        <Img
          fluid={data.milliondollar.childImageSharp.fluid}
          alt="Phelps Million Dollar Advocates Membership Logo"
          className="m-4 w-32"
        />
      </div>
      </div>
  )
}

export default Certifications