import React, { useState, useEffect } from "react";
import Videomp4 from "../assets/bgvideo.mp4"
// import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import { graphql, useStaticQuery } from 'gatsby'

// import Poster from "../assets/poster.jpg"
import header from '../../data/header';

import Div100vh from 'react-div-100vh'

import { Link } from "gatsby"
import Logo from "../assets/svg/logo-w.svg" 
import Navigation from '../components/navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt} from '@fortawesome/free-solid-svg-icons'
import { useIntl } from "react-intl"

export default function Header({locale}) {
  const data = header[locale];
  const intl = useIntl()
  const [allowVideo, setAllowVideo] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  useEffect(() => {
    setAllowVideo(true);
  }, []);

  const videoLoaded = () => {
    setIsVideoLoaded(true);
  };
  const img = useStaticQuery(
    graphql`
      query {
        poster: file(relativePath: { eq: "poster.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }        
      }
    `
  )
  return ( 
      <Div100vh className="video-wrap header"  id="header">
        {allowVideo && (
          <video
            muted
            loop
            autoPlay
            playsInline
            onCanPlayThrough={videoLoaded}
            className={`${isVideoLoaded ? "show" : "hide"}`}
          >
            <source
              src={Videomp4}
              type="video/mp4"
            />
          </video>

        )}

        <div className={`fallback ${isVideoLoaded ? "hide" : "show"}`}>
          <Img fluid={img.poster.childImageSharp.fluid} className={`fallback`} />
        </div> 
        <div className="text-overlay h-full">
          <Link to="/" className="logo "> 
            <Logo className="h-12 lg:h-16 w-40 lg:w-72 my-2 lg:mt-5 lg:mx-0 mx-3"/> 
          </Link>
          <Navigation locale={locale} />
          <div className="
            header-text 
            text-white 
            text-center 
            justify-center 
            items-center
            flex 
            h-full
            ">
            <h2 className="max-w-3xl md:text-5xl text-4xl p-12 leading-none">
              {data.text}
            </h2>
            <div className="call max-w-3xl absolute bottom-0 sm:text-xl text-lg ">
              <p className="uppercase tracking-wide text-base font-bold">
              {intl.formatMessage({ id: "ctaLine1" })}
              <br />
              {intl.formatMessage({ id: "ctaLine2" })}
              </p>
              <a property="telephone" href={`tel:${data.tel}`}
                className="inline-block sm:text-2xl text-xl mt-4 mb-12 flex-shrink-0 bg-primary-600 py-2 px-4 rounded-lg shadow-md hover:bg-primary-700"
              >
                <FontAwesomeIcon fixedWidth icon={faPhoneAlt} className="text-primary-100 mr-1" />
                  {data.tel}
              </a>
            </div>
          </div>
        </div>
        
        <div className="background-overlay" />
      </Div100vh>

  );
}


// export default Header