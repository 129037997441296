import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt} from '@fortawesome/free-solid-svg-icons'
import results from '../../data/results';
import { useIntl } from "react-intl"

SwiperCore.use([Autoplay, Pagination, Navigation]);

const Results = ({locale}) => {
  const intl = useIntl()
  const localeResults = results[locale]
  const data = useStaticQuery(
    graphql`
      query {
        books: file(relativePath: { eq: "books.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }        
      }
    `
  )

  return (
    <BackgroundImage 
    Tag={`section`} fluid={data.books.childImageSharp.fluid}>
      <div className="text-white text-center pb-12 px-4 mx-auto">   
      <Swiper
        className="p-12 text-shadow mb-12"
        spaceBetween={50}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        loop={true}
      >
        {localeResults.results.map((item, index) => {
            return (
              <SwiperSlide key={index} className="py-16 px-4 text-lg font-bold">
                <span className="text-5xl block">{item.title}</span> 
                {item.text}
              </SwiperSlide>
            )
        })}
      </Swiper>

      <p className="my-4 uppercase tracking-wide font-bold">
        {intl.formatMessage({ id: "ctaLine1" })}
        <br />
        {intl.formatMessage({ id: "ctaLine2" })}
      </p>
      <a href="tel:3056482255" className="inline-block sm:text-2xl text-xl flex-shrink-0 bg-primary-600 py-2 px-4 rounded-lg shadow-md hover:bg-primary-700">
        <FontAwesomeIcon fixedWidth icon={faPhoneAlt} className="text-primary-100 mr-1" />
        (305) 648-2255
      </a>
      </div>
    </BackgroundImage>
  )
}

export default Results