import React from 'react';
import {
  Header,
  Info,
  Areas,
  Seo,
  Results,
  Reviews,
  Gallery,
  Contact
} from '../components';


import { useLocalization } from "gatsby-theme-i18n"


import '../main.css';
import '../swiper.css';




const Home = () =>{
  const {locale} = useLocalization()

  return (
    <main className="antialiased"> 
      <Seo title="Personal Injury Lawyer | The Bennett Law Center | South Florida" />
      <Header locale={locale}/>
      <Info locale={locale}/>
      <Results locale={locale} /> 
      <Areas locale={locale}/>
      <Reviews locale={locale} />
      <Gallery locale={locale} />
      <Contact locale={locale}/>
    </main>
  );
}


export default Home;
