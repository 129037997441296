import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"


const InfoCard = ({ index, title, text, mail, tel}) => {
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
    triggerOnce: true,
  })

  const data = useStaticQuery(
    graphql`
      query {
        scott: file(relativePath: { eq: "scott.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 768, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        richard: file(relativePath: { eq: "richard.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 768, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )



  let avatar;
  switch(index) { 
    case 0:
      avatar = data.scott;
      break;
    case 1:
      avatar = data.richard;
      break;
    default:
      avatar = data.scott;
      break;
  }

  return (
    <section ref={ref} className={`
        card  
        md:items-end 
        bg-white 
        md:flex 
        md:ml-12 
        md:my-16
        my-12 
        border-neutral-300 border
      ${inView?"activo":"inactivo"}`} >
      
      <header className="
        md:w-84 
        md:flex-shrink-0 
        md:pr-12 
        md:pt-8 
        relative
      ">
        
        <address className="bg-white text-right not-italic absolute md:static bottom-0 z-10 p-4 
          md:pr-0 space-y-1 
        ">
          <h1 className="text-blue text-3xl">{title}</h1>
          <a className="block text-xs truncate" href={`mailto:${mail}`}>{mail}</a>
          <a className="block text-xs" href={`tel:${tel}`}>{tel}</a>
        </address>

        <Img 
          imgStyle={{height: 375, objectPosition: 'top'}}
          fluid={avatar.childImageSharp.fluid} className="card-image md:-ml-12" 
          style={{height: 375}}
        />
      </header> 

      <div className="place-self-center md:p-12 p-4 md:pl-0 space-y-6 text-blue text-sm" dangerouslySetInnerHTML={{ __html: text }} /> 
    </section>
  )
}

export default InfoCard