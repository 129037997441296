import React from "react"
import info from '../../data/info';
import InfoCard from '../components/info-card'
import { useLocalization } from "gatsby-theme-i18n"

const Info = ({ className }) => {
  const { locale } = useLocalization()
  const info2 = info[locale];
  const {titulo, descripcion} = info2; 

  
  return (
    <section id="info" className="bg-neutral-200 mb-12 md:mb-16">
      <section id="intro" className="bg-lines md:flex md:flex-row">  
          <header className="bg-lines md:flex md:items-center md:text-right text-center">
            <h1 className="text-4xl text-white md:pr-12 md:pl-0 py-16 md:py-24 px-8"
            >
            {titulo}
            </h1>
          </header>
          <p className="  text-blue bg-white py-16 md:py-24 px-8 border-neutral-300 border">
            {descripcion}
          </p>
      </section>
      <section id="staff" className="
        mx-auto 
        max-w-6xl 
        px-4 
        ">
        {info2.recuadros &&
          Object.keys(info2.recuadros).map((item, index)=> (
              <InfoCard 
                index={index}
                key={`${index}-side`} 
                title={info2.recuadros[item].titulo}
                text={info2.recuadros[item].texto}
                mail={info2.recuadros[item].mail}
                tel={info2.recuadros[item].tel}
              />
        ))}
      </section>
    </section>

  )
}

export default Info