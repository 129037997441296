import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import reviews from '../../data/reviews';

SwiperCore.use([Autoplay, Pagination, Navigation]);

const Reviews = ({locale}) => {
  const localeReviews = reviews[locale]
  const data = useStaticQuery(
    graphql`
      query {
        reviewsbg: file(relativePath: { eq: "reviewsbg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }        
      }
    `
  )

  return (
    <BackgroundImage 
    Tag={`section`} fluid={data.reviewsbg.childImageSharp.fluid}>
      <div className="reviews text-white text-center py-16 px-4 mx-auto">   
      <Swiper
        className="p-12 text-shadow max-w-4xl"
        spaceBetween={50}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        loop={true}
      >
        {localeReviews.reviews.map((item, index) => {
            return (
              <SwiperSlide key={index} tag="figure" className="pb-12 px-4 text-lg space-y-6">
                <blockquote cite={item.url}>
                  {item.text}
                </blockquote>
                <figcaption className="text-xl">
                  <a href={item.url}>{item.name}</a>
                </figcaption>
              </SwiperSlide>
            )
        })}
      </Swiper>
      </div>
    </BackgroundImage>
  )
}

export default Reviews