import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import areas from '../../data/areas';
import BackgroundImage from 'gatsby-background-image'

const Areas = ({locale}) => {
  const localeAreas = areas[locale]
  const data = useStaticQuery(
    graphql`
      query {
        Personal: file(relativePath: { eq: "1.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420, duotone: {
              highlight: "#687a92",
              shadow: "#152131",
              opacity: 95
            }) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        Insurance: file(relativePath: { eq: "2.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420, duotone: {
              highlight: "#687a92",
              shadow: "#152131",
              opacity: 95
            }) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        Auto: file(relativePath: { eq: "3.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420, duotone: {
              highlight: "#687a92",
              shadow: "#152131",
              opacity: 95
            }
              ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        Criminal: file(relativePath: { eq: "4.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420,  duotone: {
              highlight: "#648ea5",
              shadow: "#23495f",
              opacity: 90
            }) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        Wrongful: file(relativePath: { eq: "5.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420,  duotone: {
              highlight: "#648ea5",
              shadow: "#23495f",
              opacity: 90
            }) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        Slip: file(relativePath: { eq: "6.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 420,  duotone: {
              highlight: "#648ea5",
              shadow: "#23495f",
              opacity: 90
            }) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bg: file(relativePath: { eq: "areasbg.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        
      }
    `
  )

  return (
    <section id="areas" className="md:py-16 py-12 flex items-center flex-col">
      <h1 className="text-blue text-center text-5xl mb-8 border-b border-blue sm:px-16 pb-2 mx-8">{localeAreas.title}</h1>
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-4 mx-auto max-w-6xl px-4 auto-rows-fr ">
      
      { Object.keys(localeAreas.cards).map((item, index)=> {
      // areas.map((item, index) => {
        return (
          <BackgroundImage
            key={index} 
            Tag={`section`}
            fluid={data[item].childImageSharp.fluid}
          >
            <div className="area text-white p-6 space-y-4">
              <h2 className="text-white block uppercase tracking-wide font-sans text-base font-normal">{localeAreas.cards[item].title}</h2>
              <p className="text-shadow text-sm leading-5">{localeAreas.cards[item].text}</p> 
            </div>
           </BackgroundImage>
        )
      })}
      </div>
    </section>
  )
}

export default Areas