import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faClock,
    faEnvelope,
    faMapPin,
    faPhoneAlt,
    faFax,
} from '@fortawesome/free-solid-svg-icons'
import {faFacebookSquare, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'

import contact from '../../data/contact';
import { useIntl } from "react-intl"


export default function Contact() {
    const intl = useIntl()
    return (
        <section id="contact" className="bg-neutral-900">
            <div className="grid md:grid-cols-2">
                <div className="mapcontainer bg-neutral-800">
                    <iframe 
                        className="map h-64"
                        src={contact.address.embed} 
                        width="100%" 
                        height="100%" 
                        loading="lazy"
                        title="Google Map">
                    </iframe>
                    <section className="self-center py-12 px-4 sm:px-8 text-white">
                        <h1 className="">
                            {intl.formatMessage({ id: "contactTitle" })} 
                        </h1>
                        <ul vocab="https://schema.org/" typeof="LegalService" className="space-y-2 text-sm border-b pb-8 border-opacity-50 border-white">
                            <li property="name" className="text-white block uppercase tracking-wide text-sm font-bold mt-4 mb-2">
                                The Bennnett Law Center
                            </li>
                            <li> 
                                <FontAwesomeIcon fixedWidth icon={faClock} className="text-primary-400 mr-1" />
                                <time property="openingHours" content={contact.officeHours.content}> {contact.officeHours.hours}</time>.
                            </li>
                            <li> <FontAwesomeIcon fixedWidth icon={faMapPin} className="text-primary-400 mr-1" /> 
                                <a property="address" typeof="PostalAddress" href={contact.address.link}>
                                    <span property="streetAddress"> {contact.address.streetAddress}</span>, 
                                    <span property="addressLocality"> {contact.address.addressLocality}</span>, 
                                    <span property="postalCode"> {contact.address.postalCode}</span>,   
                                    <span property="addressCountry"> {contact.address.addressCountry}</span>
                                </a>
                            </li>
                            <li><FontAwesomeIcon fixedWidth icon={faPhoneAlt} className="text-primary-400 mr-1" />  
                                <a property="telephone" href={`tel:${contact.telephone}`}>{contact.telephone}</a>
                            </li>
                            <li><FontAwesomeIcon fixedWidth icon={faFax} className="text-primary-400 mr-1" />  
                                <a property="faxNumber" href={`fax:${contact.fax}`}>{contact.fax}</a>
                            </li>
                            <li><FontAwesomeIcon fixedWidth icon={faEnvelope} className="text-primary-400 mr-1" />  
                                <a property="email" href={`mailto:${contact.email}`}>{contact.email}</a>
                            </li>
                        </ul>
                        <h2 className="text-white block uppercase tracking-wide text-sm font-sans mt-8 mb-4">Social Media</h2>
                        <ul className="space-x-4">
                            <li className="inline-block">
                                <a target="_blank" rel="noreferrer" className="hover:text-primary-600" href={contact.social.facebook}>
                                    <FontAwesomeIcon  icon={faFacebookSquare} size="2x" />
                                </a>
                            </li>
                            <li className="inline-block">
                                <a target="_blank" rel="noreferrer" className="hover:text-primary-600" href={contact.social.instagram}>
                                    <FontAwesomeIcon  icon={faInstagram} size="2x" />
                                </a>
                            </li>
                            <li className="inline-block">
                                <a target="_blank" rel="noreferrer" className="hover:text-primary-600" href={contact.social.youtube}>
                                    <FontAwesomeIcon  icon={faYoutube} size="2x" />
                                </a>
                            </li>
                            <li className="inline-block">
                                <a target="_blank" rel="noreferrer" className="hover:text-primary-600" href={contact.social.linkedin}>
                                    <FontAwesomeIcon  icon={faLinkedin} size="2x" />
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>
                <form className="w-full py-12 px-4 sm:px-8" method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <h1 className="text-white pb-4">
                        {intl.formatMessage({ id: "ctaLine1" })} 
                    </h1>
                    <h2 className=" text-white uppercase tracking-wide text-sm font-sans border-b border-opacity-50 border-white pb-8">
                        {intl.formatMessage({ id: "ctaLine2" })} 
                    </h2>
                    <div className="flex flex-wrap -mx-3 my-6 pt-6 ">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="text-white block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="first-name">
                                {intl.formatMessage({ id: "First Name" })} 
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="first-name" name="first-name" type="text" placeholder="Jane" required />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="text-white block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="last-name">
                                {intl.formatMessage({ id: "Last Name" })} 
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="last-name" name="last-name" type="text" placeholder="Doe" required />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="text-white block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="email">
                                {intl.formatMessage({ id: "E-mail" })}  
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" name="email" type="email" required />
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="text-white block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="phone">
                                {intl.formatMessage({ id: "Phone" })}  
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone" name="phone" type="tel" required />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="text-white block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="message">
                                {intl.formatMessage({ id: "Message" })}  
                            </label> 
                            <textarea className="no-resize appearance-none block w-full bg-gray-200 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none" id="message" name="message" required></textarea>
                        </div>
                    </div>
                    <div className="md:flex md:items-center">
                        <div className="md:w-1/3">
                            <button className="shadow bg-primary-600 hover:bg-primary-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
                            {intl.formatMessage({ id: "Send" })}  
                            </button>
                        </div> 
                    </div>
                </form>
            </div>
        </section>
    )
}

