import React, { useState } from "react";
import SwiperCore, {Controller, Thumbs} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import Certifications from '../components/certifications'


SwiperCore.use([Controller,Thumbs]);

// export default ({locale}) => {
export default function Gallery({locale}) {
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);

    const data = useStaticQuery(graphql`
        query Gallery {
            images: allFile(
            filter: { relativeDirectory: { eq: "gallery" } }
            sort: { fields: name }
            ) {
            edges {
                node {
                childImageSharp {
                    thumb: fluid(maxWidth: 270, maxHeight: 270) {
                    ...GatsbyImageSharpFluid_withWebp
                    originalName
                    }
                    full: fluid(maxWidth: 1080, maxHeight: 720) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
                }
            }
            }
        }
    `)
    

    const images = data.images.edges.map(({ node }) => ({
        ...node.childImageSharp,
        // Use original name as caption.
        // The `originalName` is queried inside the `thumb` field,
        // but the `Gallery` component expects `caption` at the top level.
        caption: node.childImageSharp.thumb.originalName,
    }))

    let thumbsArray, fullArray, thumbAltArray


    // New style with all images in one prop

    // thumbsArray = images.filter((thumb) => thumb !== undefined).map(({ thumb }) => thumb)
    fullArray = images.filter((full) => full !== undefined).map(({ full }) => full)

    thumbAltArray = images.map(({ thumbAlt }) => thumbAlt)

    thumbsArray = images.filter((thumb) => thumb !== undefined).map(({ thumb }) => thumb)


  return (
    <div className="max-w-6xl m-auto my-12 md:my-16 px-4 auto-rows-fr md:grid md:gap-4 md:grid-cols-2">
        <div className="gallery fullw mb-12 md:mb-0">
            <Swiper
                onSwiper={setFirstSwiper} 
                controller={{ control: secondSwiper }}

                className="fullw"

                loopedSlides={4}
                // pagination={{ clickable: true }}
                loop={true}
                // navigation
            >

                {fullArray.map((thumbnail, thumbIndex) => {
                return (
                    <SwiperSlide key={thumbIndex}>
                        <Img fluid={thumbnail} alt={thumbAltArray? thumbAltArray[thumbIndex]? thumbAltArray[thumbIndex]: '': '' } />
                    </SwiperSlide>
                )
                })}
            </Swiper>

            <Swiper
                onSwiper={setSecondSwiper} 
                controller={{ control: firstSwiper }}
                watchSlidesVisibility
                watchSlidesProgress
                spaceBetween={10}
                centeredSlides= {true}
                slidesPerView= {5}
                touchRatio= {0.2}
                slideToClickedSlide= {true}
                loop= {true}
                loopedSlides= {4}
                // navigation

                className="thumbs fullw mt-2"
            >

                {thumbsArray.map((thumbnail, thumbIndex) => {
                return (
                    <SwiperSlide key={thumbIndex}>
                        <Img fluid={thumbnail} alt={thumbAltArray? thumbAltArray[thumbIndex]? thumbAltArray[thumbIndex]: '': '' } />
                    </SwiperSlide>
                )
                })}
            </Swiper>
        </div>
        <Certifications locale={locale} />
    </div>
  );
};